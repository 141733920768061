<template>
  <div class="container">
    <div class="row">
      <div class="col-md-3">
        <form>
          <div class="form-group">
            <label for="">{{ 'LOOKING_FOR_JOB' | translate }}</label>
            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" :placeholder="'LOOKING_FOR_JOB' | translate">
          </div>
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="check1">
            <label class="form-check-label" for=""> All </label><br>
            <input type="checkbox" class="form-check-input" id="check">
            <label class="form-check-label" for=""> ajliin bair 1 </label><br>
            <input type="checkbox" class="form-check-input" id="check3">
            <label class="form-check-label" for=""> ajliin bair 2 </label><br>
            <input type="checkbox" class="form-check-input" id="">
            <label class="form-check-label" for=""> ajliin bair 3 </label><br>
            <input type="checkbox" class="form-check-input" id="">
            <label class="form-check-label" for="">ajliin bair 4 </label><br>
            <input type="checkbox" class="form-check-input" id="">
            <label class="form-check-label" for=""> ajliin bair 5 </label><br>
            <input type="checkbox" class="form-check-input" id="">
            <label class="form-check-label" for=""> ajliin bair 6 </label><br>
            <input type="checkbox" class="form-check-input" id="">
            <label class="form-check-label" for="">ajliin bair 7  </label><br>
            <input type="checkbox" class="form-check-input" id="">
            <label class="form-check-label" for="">ajliin bair 8  </label><br>
            <input type="checkbox" class="form-check-input" id="">
            <label class="form-check-label" for="">ajliin bair 9 </label><br>
            <input type="checkbox" class="form-check-input" id="">
            <label class="form-check-label" for=""> ajliin bair 10 </label><br>
            <input type="checkbox" class="form-check-input" id="">
            <label class="form-check-label" for=""> ajliin bair 11 </label><br>
          </div>
          <button type="submit" class="btn btn-primary">{{ 'send' | translate }}</button>
        </form>
      </div>
      <div class="col-md-9">
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: "p_workplace"
}
</script>

<style scoped>

</style>
